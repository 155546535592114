:root {
  --primary-color: #03a9f4;
  --secondary-color: #EF7900;
  --smoke-white: #F3F6FE;
  --grey-color: #666666;
  --dark-grey-color: #333333;
  --sky: #1897c8;
  --lavender-syrup: #e4e6f0;
  --black: #2b2b2b;
  --hazy-sky: #b8bbd6;
  --old-mill-blue: #6f7185;
  --great-white: #f3f6fe;
  --light-purple: #b7bbd8;
  --red: #ff0000;
  --blackopacity50: rgba(0, 0, 0, 0.5);
  --blackopacity10: rgba(0, 0, 0, 0.1);
  --blackopacity20: rgba(0, 0, 0, 0.2);
  --light-green: #004d00;
  --white: #ffff;
  --white-with-opacity: #ff00ff05;
  --grey: #e6e8f4;
  --dot: #f17300;
  --golden: #f2bb05;
  --transparent: transparent;
  --dark-grey: #38393b;
}
.white_smoke{
  background-color: var(--smoke-white);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
/* ol,
ul {
} */
nav ul li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none !important;
  color: var(--black) !important;
}

.footer_padding a:hover {
  color: var(--white) !important;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #fff inset; /* Change the color to your own background color */
  box-shadow: transparent;
  -webkit-text-fill-color: #fff;
}
input {
  color: var(--dark-grey-color);
  background-color: var(--smoke-white);
}
body {
  background: #fff;
  color: var(--black);
  font-family: "Montserrat" !important;
  overflow-x: hidden;
  font-size: 16px;
  font-weight: 400;
}
.cl-white {
  color: white !important;
}
.cl-secondary {
  color: var(--secondary-color) !important;
}
/* .container {
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    padding: 0px 30px;
  } */


@media only screen and (max-width: 859px) {
  .container {
    padding: 0 15px;
  }

  .main_logo1 {
    width: 70% !important;
    height: 25% !important;
  }
}
.regular {
  font-weight: 400 !important;
}
.semiBold {
  font-weight: 600 !important;
}
.extraBold {
  font-weight: 800 !important;
}

/* HELLPERS */
.textCenter {
  text-align: center;
}
.animate {
  transition: 0.3s ease;
}
.pointer {
  cursor: pointer;
}
.relative {
  position: relative;
}
.textCenter {
  text-align: center;
}
.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}
.x100 {
  width: 100%;
}
.active {
  border-bottom: 2px solid white;
  color: white;
}
.tag {
  padding: 5px 10px;
  margin: 10px 0;
}
.shadow {
  box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
}
/* FLEXBOX */
.flex {
  display: flex;
}
.flexSpaceCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flexNullCenter {
  display: flex;
  align-items: center;
}
.flexColumn {
  flex-direction: column;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexSpaceNull {
  display: flex;
  justify-content: space-between;
}

/* FONT SIZES */
.font11 {
  font-size: 0.688rem;
}
.font12 {
  font-size: 0.75rem;
}
.font13 {
  font-size: 0.813rem;
}
.font14 {
  font-size: 0.875rem;
}
.font15 {
  font-size: 0.938rem;
}
.font18 {
  font-size: 1.125rem;
}
.font20 {
  font-size: 1.25rem;
}
.font25 {
  font-size: 1.563rem;
}
.font30 {
  font-size: 1.875rem;
}
.font40 {
  font-size: 2.5rem;
}
.font60 {
  font-size: 3.75rem;
  line-height: 4.5rem;
}
.waitlist-btn {
  background-color: var(--secondary-color) !important;
}
.cl-orange {
  color: var(--secondary-color);
}
.rounded-radius {
  border-radius: 50px;
}
.padding {
  padding: 12px 30px;
}
@media (min-width: 900px) {
  .mw-75 {
    width: 65%;
  }
}

@media only screen and (max-width: 992px) {
  .footer_padding img {
    width: 30%;
    height: 30%;
  }
  .font16 {
    font-size: 0.813rem;
  }
}
.sidebar_logo{
  width: 50%;
  height: 50%;
}
.serveus_logo_navbar{
  width: 12%;

  @media  (max-width: 1200px) {
    width: 15%;
  }
  @media  (max-width: 992px) {
    width: 18%;
  }
  @media  (max-width: 768px) {
    width: 22%;
  }
  @media  (max-width: 500px) {
    width: 25%;
  }
}

@media only screen and (max-width: 859px) {
  .width-sm {
    width: 50% !important;
  }
  .main_logo1 {
    width: 70% !important;
    height: 40% !important ;
  }
  .footer_padding {
    padding: 5px 0px !important;
  }
  .font11 {
    font-size: 0.688rem;
  }
  .font12 {
    font-size: 0.688rem;
  }
  .font13 {
    font-size: 0.75rem;
  }
  .font14 {
    font-size: 0.813rem;
  }
  .font15 {
    font-size: 0.813rem;
  }
  .font18 {
    font-size: 0.938rem;
  }
  .font20 {
    font-size: 1.125rem;
  }
  .font25 {
    font-size: 1.25rem;
  }
  .font30 {
    font-size: 1.5rem;
  }
  .font40 {
    font-size: 1.875rem;
  }
  .font60 {
    font-size: 2.8rem;
  }
}
/* BORDER RADIUS */
.radius8 {
  border-radius: 1rem;
}
.radius6 {
  border-radius: 0.6rem;
}
/* COLORS */
.darkColor {
  color: var(--primary-color);
}
.white_smoke{
  background-color: var(--smoke-white);
}
.primaryColor{
  color: var(--primary-color)
  }
.secondaryColor{
  color: var(--secondary-color)
  }
.purpleColor {
  color: #7620ff;
}
.orangeColor {
  color: #f2b300;
}
.lightColor {
  color: #f5f5f5;
}
.coralColor {
  color: #c4ffff;
}
.greenColor {
  color: #49cb86;
}
.greyColor {
  color: #707070;
}
.whiteColor {
  color: #fff;
}
/* BACKGROUNDS */
.darkBg {
  background-color: var(--primary-color);
}
.purpleBg {
  background-color: #7620ff;
}
.orangeBg {
  background-color: #f2b300;
}
.lightBg {
  background-color: #f5f5f5;
}
.coralBg {
  background-color: #c4ffff;
}
.greenBg {
  background-color: #49cb86;
}
.greyBg {
  background-color: #707070;
}
.whiteBg {
  background-color: #fff;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.footer_padding {
  padding: 20px 0px;
}
.main_logo {
  width: 25%;
  height: 25%;
}

@media only screen and (max-width: 576px) {
  .main_logo {
    width: 30% !important;
    height: 30% !important;
  }
  .width-sm {
    width: 100% !important;
  }
}
.mt-100 {
  margin-top: 100px;
}
.pt-100 {
  padding-top: 100px;
}

/* .ul {
  list-style: initial;
} */
.local_spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 50%;
  color: var(--primary-color) !important;
}
.membership {
  padding: 10px 10px;
}
.membership {
  padding: 10px 10px;
  background: white;
  border-radius: 1rem;
  margin: 5px;
}
.terms_of_use {
  padding-bottom: 200px;
  p {
    color: var(--grey-color);
    font-size: 16px;
  }
  ol li {
    color: var(--grey-color);
    font-size: 16px;
  }
}
.privacy_policy {
  padding-bottom: 200px;
  p {
    color: var(--grey-color);
    font-size: 16px;
    margin-bottom: 1rem;
  }
  ul li {
    color: var(--grey-color);
    font-size: 16px;
    
  }
  ul{
    margin-bottom: 1rem;
    /* padding-left: 2rem; */
  }
  h5{
    margin-bottom: 0.5px;
  }
}
.tooltip_carousal {
  width: 400px;
  height: 500px;
}
.tooltip-inner {
  max-width: 100% !important;
}

/* card */
.pricing-card {
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  width: 300px;
  margin: auto;
}

.pricing-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #555;
}

.pricing-price {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
}

.get-started-btn {
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
}

.text-muted {
  color: #ccc !important;
}
.tooltip-inner {
  background-color: #b1bdc5 !important;
}
.tooltip {
  border: none !important;
}
.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: white !important;
}

.subscription {
  .slick-prev:before {
    background: none;
    color: var(--primary-color);
  }
  .slick-next:before {
    background: none;
    color: var(--primary-color);
  }
  padding: 16px;
  margin: 40px auto;
  .pricing-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4) !important;
  }
  .bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: white !important;
  }
  @media screen and (max-width: 992px) {
    margin: 60px auto;
    padding-bottom: 130px;
  }
}
.pricing-card {
  font-family: "Montserrat" !important;
}
.dollar {
  position: relative;
  bottom: 25px;
}
.price {
  font-size: 42px;
  font-weight: 600;
}
.list-unstyled {
  font-size: 12px;
}
.get-started-btn {
  background-color: var(--primary-color) !important ;
}
.free_trial {
  .get-started-btn {
    background: #000000 !important;
  }
  h4 {
    background: #000000;
    color: white !important;
    border-radius: 0 0px 13px 13px;
  }
}
.monthly {
  .get-started-btn {
    background: var(--primary-color) !important;
  }
  h4 {
    background: var(--primary-color);
    border-radius: 0 0px 13px 13px;
  }
}
.enterprise {
  .get-started-btn {
    background: #0b254e !important;
  }
  h4 {
    background: #0b254e;
    color: white !important;
    border-radius: 0 0px 13px 13px;
  }
}
.golden {
  .get-started-btn {
    background: #f0c10c !important;
  }
  h4 {
    background: #f0c10c;
    border-radius: 0 0px 13px 13px;
  }
}
.tick_marks {
  color: var(--primary-color);
}
.text-primary-local {
  font-family: "Montserrat";
}
.text-primary-local span {
  color: var(--primary-color);
}
.slick-prev:before{
    color: var(--primary-color) !important;
}
.slick-next:before{
    color: var(--primary-color) !important;
}

/* Support Page  */
.faq-accordion {
  margin: 20px 0;
}
.faq-item {
  margin-bottom: 10px;
  /* border: 1px solid #ccc; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  border-radius: 5px;
  background-color: white;
}
.faq-question {
  background: #f7f7f7;
  /* background-color: white; */

  border: none;
  cursor: pointer;
  padding: 16px;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}
.faq-question.active {
  background: white;
  font-weight: bold;
  color: var(--black);
  transition: 0.3s ease-in-out;

}
.faq-answer {
  padding: 15px;
  background: #fff;
  border-top: 1px solid #ccc;
  font-size: 14px;

  transition: 0.3s ease-in-out;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
@media (max-width: 575px){
  .container-xs{
    max-width: 460px;
  }
}
.fadeInSlide {
  animation: fadeInSlide 0.4s ease-out forwards;
}

@keyframes fadeInSlide {
  0% {
    opacity: 0;
    transform: translateX(-100%); /* Starts off-screen to the left */
  }
  100% {
    opacity: 1;
    transform: translateX(0%); /* Ends at the default position */
  }
}

.rfm-marquee-container{
  overflow-x : auto !important;
  scrollbar-width: none;
  }
  .gm-ui-hover-effect{
    /* height: 0 !important;
    top: 10px !important; */
    padding: 0 !important;
    height: 0 !important;
  }
  .gm-style-iw-ch{
    padding-top: 9px !important;
  }

.info-window-content {
  display: flex;
  flex-direction: column;
  max-width: 250px;
}

.info-window-header {
  position: relative;
  width: 100%;
  height: 150px;
}

.info-window-image {
  width: 225px;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}
.alter-image{
  width: 200px;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}
.info-window-body {
  padding: 10px;
}

.info-window-body h4 {
  margin: 0;
  font-size: 18px;
}

.info-window-body p {
  font-size: 14px;
  color: #555;
}

.info-window-body a {
  display: inline-block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.info-window-body a:hover {
  text-decoration: underline;
}

.custom-message {
  position: fixed;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--secondary-color);
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  animation: slide-up 0.5s ease forwards, fade-out 0.5s ease 1.5s forwards;
}

@keyframes slide-up {
  from {
    bottom: -80px;
    opacity: 0;
  }
  to {
    bottom: 50px;
    opacity: 1;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}
